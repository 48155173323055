<template>
  <div class="ActivitgoodsList">
    <div class="title">
      <div class="seach">
        <div class="inputs">
          <el-input
            v-model="from.supplier_title"
            placeholder="请输入供应商"
            clearable
          ></el-input>
        </div>
        <div class="inputs">
          <el-input
            v-model="from.activity_name"
            placeholder="请输入活动名称"
            clearable
          ></el-input>
        </div>
        <div class="inputs">
          <el-select
            placeholder="选择集配中心"
            v-model="from.logistic_business_id"
            clearable
            filterable
          >
            <el-option
              v-for="item of options"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
        </div>
        <div class="inputs">
          <el-input
            v-model="from.buyer_name"
            placeholder="请输入采购"
            clearable
          ></el-input>
        </div>
        <div class="inputs">
          <el-input
            v-model="from.product_title"
            placeholder="请输入商品名称"
            clearable
          ></el-input>
        </div>
        <div class="inputs">
          <el-input
            v-model="from.sku_code"
            placeholder="请输入货号"
            clearable
          ></el-input>
        </div>

        <div class="inputs">
          <el-select
            v-model="from.audit_status"
            clearable
            placeholder="请选择审核状态"
          >
            <el-option label="通过" value="1"></el-option>
            <el-option label="未通过" value="0"></el-option>
          </el-select>
        </div>
        <div class="inputs">
          <el-input
            v-model="from.add_name"
            placeholder="请输入添加人"
            clearable
          ></el-input>
        </div>
        <div class="inputs">
          <el-select
              v-model="from.first_cid"
              clearable
              filterable
              placeholder="选择一级品类"
            >
              <el-option
                v-for="item of CategoriesList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              ></el-option>
            </el-select>
        </div>
        <div class="inputs">
          <el-select
              v-model="from.cid"
              filterable
              clearable
            
              placeholder="选择二级品类"
            >
              <el-option
                v-for="item of subList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              ></el-option>
            </el-select>
        </div>
        <el-button
          type="primary"
          icon="el-icon-refresh"
          class="bjsearch"
          @click="Refresh"
        ></el-button>
        <el-button @click="search" type="primary" icon="el-icon-search"
          >查询</el-button
        >
        <el-button @click="exportHandle" :disabled="disabledExport" type="warning" icon="el-icon-upload2"
          >导出</el-button
        >
      </div>
    </div>

    <div class="auto-table-flex">
      <el-table
        :data="tableData"
        height="100%"
        v-loading="loading"
        :border="true"
        :header-cell-style="{ color: '#333333', background: '#EFF6FF' }"
        ref="multipleTable"
        tooltip-effect="dark"
        style="width: 100%"
      >
        <el-table-column prop="promotion_activity.name" align="center" label="活动名称">
        </el-table-column>
        <el-table-column prop="product.title" align="center" label="商品名称">
        </el-table-column>
        <el-table-column
          prop="product.supplier.title"
          align="center"
          label="供应商"
        >
        </el-table-column>
        <el-table-column prop="product.buyer.fullname" align="center" label="采购">
        </el-table-column>
        <el-table-column
          prop="product.categories.first_category.name"
          align="center"
          label="一级品类"
        >
        </el-table-column>
        <el-table-column
          prop="product.categories.name"
          align="center"
          label="二级品类"
        >
        </el-table-column>
        <el-table-column prop="price" align="center" label="提报价格">
        </el-table-column>
        <el-table-column prop="stock" align="center" label="提报库存">
        </el-table-column>
        <el-table-column prop="create_time" align="center" label="添加时间">
        </el-table-column>
        <el-table-column prop="audit_time" align="center" label="审核通过时间">
        </el-table-column>
        <el-table-column
          prop="business_user.username"
          align="center"
          label="添加人"
        >
        </el-table-column>
        <el-table-column prop="audit_status" align="center" label="商品状态">
          <template slot-scope="scope">
            <div>
              {{ scope.row.audit_status == 1 ? "通过" : "未通过" }}
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="product.price" align="center" label="开市价">
        </el-table-column>
        <el-table-column prop="product.stock" align="center" label="库存">
        </el-table-column>
        <el-table-column
          prop="product.product_info.score_30_day"
          align="center"
          label="30天得分"
        >
        </el-table-column>
        <el-table-column
          prop="aftersale_statistics.prercent"
          align="center"
          label="商品售后率"
        >
        </el-table-column>
        <el-table-column
          prop="activity_sales"
          align="center"
          label="活动期间销量"
        >
        </el-table-column>
        <el-table-column prop="sort_num" align="center" label="排序">
        </el-table-column>
        <el-table-column align="center" label="操作">
          <template slot-scope="scope">
            <el-button type="text" @click="handleDel(scope.row)"
              >删除</el-button
            >
            <el-button type="text" v-if="scope.row.audit_status==0" @click="handleEdit(scope.row)">通过</el-button>
          
          </template>
        </el-table-column>
      </el-table>
    </div>

    <div class="fy">
      <el-pagination
        type="primary"
        background
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="from.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>
  </div>
</template>
        <script>
        import { getCategoriesList } from "@/api/generalControl/category";
        import { getSubList } from "@/api/generalControl/index.js";
export default {
  name: "ActivitgoodsList",
  components: {},

  data() {
    return {
      //列表新建页面初始参数
      loading: false,
      from: {
        supplier_title: "", //  供应商名
        buyer_name: "", //  提报人
        product_title: "", //  商品名
        sku_code: "", //  货号
        audit_user_name: "", //  审核人
        cid_name: "", //   二级分类
        first_cid_name: "", //  一级分类
        cid: "", //   二级分类
        first_cid: "", //  一级分类
        add_name: "", //
        audit_status: "",
        activity_name:'',
        logistic_business_id:'',
        promotion_activity_id:'',
        page: 1,
        pageSize: 50,
      },
      from1: {},
      tableData: [],
      currentPage: 1,
      total: 0,
      options: [],
      optionsUserd: [],
      subList: [],
      CategoriesList: [],
      disabledExport:false
    };
  },
  created() {
   
    if(this.$route.query){
      this.from.activity_name = this.$route.query.name; 
      this.from.promotion_activity_id = this.$route.query.promotion_activity_id; 
    }
    this.from1 = JSON.parse(JSON.stringify(this.from));
    this.hqlist();
    this.getAjaxCategoriesList();
    this.getAjaxSubList();
    this.getBusinessList();
  },
  methods: {
       /**
     * 导出后引导弹框
     */
     goExportCenter() {
      // 新窗口打开下载中心
      const routerInfo = this.$router.resolve({
        path: "/export-center",
      });
      window.open(routerInfo.href, "_blank");
      this.disabledExport = false;
    },
        //导出
        exportHandle() {
      let params = {
        ...this.from1,
      };
      delete params.page;
      delete params.pageSize;

      this.disabledExport = true;
      // this.$message.success({
      //   message: "正在导出，请耐心等待",
      //   duration: 99999999,
      // });
      
        this.$api.biexport.activityproductexport(params).then(res=>{
              this.disabledExport = false;
        
        this.goExportCenter()
      }).catch(err=>{
            this.disabledExport = false;
        
      })

    },
      // 获取集配中心数据
      getBusinessList() {
      this.$api.general.businessList().then((res) => {
        this.options = res.data;
      });
    },
    async getAjaxCategoriesList() {
      let { data } = await getCategoriesList();
      this.CategoriesList = data
    },
    async getAjaxSubList() {
      try {
        const { data } = await getSubList({ is_audit: 1 });
        this.subList = data;
        console.log("ajax getAjaxSubList", data);
      } catch (err) {
        console.log("ajax getAjaxSubList err", err);
      }
    },
    handleEdit(row) {
      this.$confirm(
       "确定要通过吗？",
        "提示",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
          center: true,
        }
      )
        .then(() => {
          this.$api.decoration
            .promotionactivityaudit({
              id: row.id,
          
            })
            .then((res) => {
              console.log(res, "停用或者开启");
              this.$message({
                type: "success",
                message: "通过成功",
              });
              this.hqlist();
            });
        })
        .catch(() => {});
    },
    handleDel(row) {
      this.$confirm(
       "确定要删除过吗？",
        "提示",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
          center: true,
        }
      )
        .then(() => {
          this.$api.decoration
            .promotionactivitydelete({
              id: row.id,
          
            })
            .then((res) => {
              console.log(res, "停用或者开启");
              this.$message({
                type: "success",
                message: "删除成功",
              });
              this.hqlist();
            });
        })
        .catch(() => {});
    },

    hqlist() {
      this.loading = true;
      this.$api.decoration
        .promotionactivityproduct(this.from1)
        .then((res) => {
          this.loading = false;
          this.tableData = res.data.data;
          this.total = res.data.total;
        })
        .catch((err) => {
          this.loading = false;
        });
    },
    search() {
      this.from.page = 1;
      this.from1 = JSON.parse(JSON.stringify(this.from));
      this.currentPage = 1;
      this.hqlist();
    },

    Refresh() {
      Object.assign(this.$data.from, this.$options.data().from);
      this.from1 = JSON.parse(JSON.stringify(this.from));
      this.currentPage = 1;
      this.hqlist();
    },
    handleSizeChange(val) {
      this.from.pageSize = val;
      this.from1.pageSize = val;
      this.hqlist();
    },
    handleCurrentChange(val) {
      this.from.page = val;
      this.from1.page = val;
      this.currentPage = val;
      this.hqlist();
    },
  },
};
</script>
        <style lang="scss">
.ActivitgoodsList {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  height: 100%;
  box-sizing: border-box;
  //   background: #ffffff;
  padding: 10px;

  .title {
    background: #ffffff;
    padding: 10px 15px;
    box-sizing: border-box;
    display: flex;
    align-items: flex-start;

    .seach {
      flex: 1;
      display: flex;
      // justify-content: flex-end;
      align-items: flex-start;
      flex-wrap: wrap;

      .inputs {
        display: flex;
        align-items: center;
        margin-right: 8px;
        margin-bottom: 10px;
      }

      .multiple-select {
        width: 250px;
      }
    }

    .bjsearch {
      background: #333333;
      border-color: #333333;
    }
  }

  .auto-table-flex {
    flex-grow: 1;
    overflow-y: hidden;
  }

  .fy {
    // background-color: #000;
    box-sizing: border-box;
    padding: 10px 20px;
  }
}
</style>
        
      