var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "ActivitgoodsList" }, [
    _c("div", { staticClass: "title" }, [
      _c(
        "div",
        { staticClass: "seach" },
        [
          _c(
            "div",
            { staticClass: "inputs" },
            [
              _c("el-input", {
                attrs: { placeholder: "请输入供应商", clearable: "" },
                model: {
                  value: _vm.from.supplier_title,
                  callback: function ($$v) {
                    _vm.$set(_vm.from, "supplier_title", $$v)
                  },
                  expression: "from.supplier_title",
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "inputs" },
            [
              _c("el-input", {
                attrs: { placeholder: "请输入活动名称", clearable: "" },
                model: {
                  value: _vm.from.activity_name,
                  callback: function ($$v) {
                    _vm.$set(_vm.from, "activity_name", $$v)
                  },
                  expression: "from.activity_name",
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "inputs" },
            [
              _c(
                "el-select",
                {
                  attrs: {
                    placeholder: "选择集配中心",
                    clearable: "",
                    filterable: "",
                  },
                  model: {
                    value: _vm.from.logistic_business_id,
                    callback: function ($$v) {
                      _vm.$set(_vm.from, "logistic_business_id", $$v)
                    },
                    expression: "from.logistic_business_id",
                  },
                },
                _vm._l(_vm.options, function (item) {
                  return _c("el-option", {
                    key: item.id,
                    attrs: { label: item.name, value: item.id },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "inputs" },
            [
              _c("el-input", {
                attrs: { placeholder: "请输入采购", clearable: "" },
                model: {
                  value: _vm.from.buyer_name,
                  callback: function ($$v) {
                    _vm.$set(_vm.from, "buyer_name", $$v)
                  },
                  expression: "from.buyer_name",
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "inputs" },
            [
              _c("el-input", {
                attrs: { placeholder: "请输入商品名称", clearable: "" },
                model: {
                  value: _vm.from.product_title,
                  callback: function ($$v) {
                    _vm.$set(_vm.from, "product_title", $$v)
                  },
                  expression: "from.product_title",
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "inputs" },
            [
              _c("el-input", {
                attrs: { placeholder: "请输入货号", clearable: "" },
                model: {
                  value: _vm.from.sku_code,
                  callback: function ($$v) {
                    _vm.$set(_vm.from, "sku_code", $$v)
                  },
                  expression: "from.sku_code",
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "inputs" },
            [
              _c(
                "el-select",
                {
                  attrs: { clearable: "", placeholder: "请选择审核状态" },
                  model: {
                    value: _vm.from.audit_status,
                    callback: function ($$v) {
                      _vm.$set(_vm.from, "audit_status", $$v)
                    },
                    expression: "from.audit_status",
                  },
                },
                [
                  _c("el-option", { attrs: { label: "通过", value: "1" } }),
                  _c("el-option", { attrs: { label: "未通过", value: "0" } }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "inputs" },
            [
              _c("el-input", {
                attrs: { placeholder: "请输入添加人", clearable: "" },
                model: {
                  value: _vm.from.add_name,
                  callback: function ($$v) {
                    _vm.$set(_vm.from, "add_name", $$v)
                  },
                  expression: "from.add_name",
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "inputs" },
            [
              _c(
                "el-select",
                {
                  attrs: {
                    clearable: "",
                    filterable: "",
                    placeholder: "选择一级品类",
                  },
                  model: {
                    value: _vm.from.first_cid,
                    callback: function ($$v) {
                      _vm.$set(_vm.from, "first_cid", $$v)
                    },
                    expression: "from.first_cid",
                  },
                },
                _vm._l(_vm.CategoriesList, function (item) {
                  return _c("el-option", {
                    key: item.id,
                    attrs: { label: item.name, value: item.id },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "inputs" },
            [
              _c(
                "el-select",
                {
                  attrs: {
                    filterable: "",
                    clearable: "",
                    placeholder: "选择二级品类",
                  },
                  model: {
                    value: _vm.from.cid,
                    callback: function ($$v) {
                      _vm.$set(_vm.from, "cid", $$v)
                    },
                    expression: "from.cid",
                  },
                },
                _vm._l(_vm.subList, function (item) {
                  return _c("el-option", {
                    key: item.id,
                    attrs: { label: item.name, value: item.id },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c("el-button", {
            staticClass: "bjsearch",
            attrs: { type: "primary", icon: "el-icon-refresh" },
            on: { click: _vm.Refresh },
          }),
          _c(
            "el-button",
            {
              attrs: { type: "primary", icon: "el-icon-search" },
              on: { click: _vm.search },
            },
            [_vm._v("查询")]
          ),
          _c(
            "el-button",
            {
              attrs: {
                disabled: _vm.disabledExport,
                type: "warning",
                icon: "el-icon-upload2",
              },
              on: { click: _vm.exportHandle },
            },
            [_vm._v("导出")]
          ),
        ],
        1
      ),
    ]),
    _c(
      "div",
      { staticClass: "auto-table-flex" },
      [
        _c(
          "el-table",
          {
            directives: [
              {
                name: "loading",
                rawName: "v-loading",
                value: _vm.loading,
                expression: "loading",
              },
            ],
            ref: "multipleTable",
            staticStyle: { width: "100%" },
            attrs: {
              data: _vm.tableData,
              height: "100%",
              border: true,
              "header-cell-style": { color: "#333333", background: "#EFF6FF" },
              "tooltip-effect": "dark",
            },
          },
          [
            _c("el-table-column", {
              attrs: {
                prop: "promotion_activity.name",
                align: "center",
                label: "活动名称",
              },
            }),
            _c("el-table-column", {
              attrs: {
                prop: "product.title",
                align: "center",
                label: "商品名称",
              },
            }),
            _c("el-table-column", {
              attrs: {
                prop: "product.supplier.title",
                align: "center",
                label: "供应商",
              },
            }),
            _c("el-table-column", {
              attrs: {
                prop: "product.buyer.fullname",
                align: "center",
                label: "采购",
              },
            }),
            _c("el-table-column", {
              attrs: {
                prop: "product.categories.first_category.name",
                align: "center",
                label: "一级品类",
              },
            }),
            _c("el-table-column", {
              attrs: {
                prop: "product.categories.name",
                align: "center",
                label: "二级品类",
              },
            }),
            _c("el-table-column", {
              attrs: { prop: "price", align: "center", label: "提报价格" },
            }),
            _c("el-table-column", {
              attrs: { prop: "stock", align: "center", label: "提报库存" },
            }),
            _c("el-table-column", {
              attrs: {
                prop: "create_time",
                align: "center",
                label: "添加时间",
              },
            }),
            _c("el-table-column", {
              attrs: {
                prop: "audit_time",
                align: "center",
                label: "审核通过时间",
              },
            }),
            _c("el-table-column", {
              attrs: {
                prop: "business_user.username",
                align: "center",
                label: "添加人",
              },
            }),
            _c("el-table-column", {
              attrs: {
                prop: "audit_status",
                align: "center",
                label: "商品状态",
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      _c("div", [
                        _vm._v(
                          " " +
                            _vm._s(
                              scope.row.audit_status == 1 ? "通过" : "未通过"
                            ) +
                            " "
                        ),
                      ]),
                    ]
                  },
                },
              ]),
            }),
            _c("el-table-column", {
              attrs: {
                prop: "product.price",
                align: "center",
                label: "开市价",
              },
            }),
            _c("el-table-column", {
              attrs: { prop: "product.stock", align: "center", label: "库存" },
            }),
            _c("el-table-column", {
              attrs: {
                prop: "product.product_info.score_30_day",
                align: "center",
                label: "30天得分",
              },
            }),
            _c("el-table-column", {
              attrs: {
                prop: "aftersale_statistics.prercent",
                align: "center",
                label: "商品售后率",
              },
            }),
            _c("el-table-column", {
              attrs: {
                prop: "activity_sales",
                align: "center",
                label: "活动期间销量",
              },
            }),
            _c("el-table-column", {
              attrs: { prop: "sort_num", align: "center", label: "排序" },
            }),
            _c("el-table-column", {
              attrs: { align: "center", label: "操作" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      _c(
                        "el-button",
                        {
                          attrs: { type: "text" },
                          on: {
                            click: function ($event) {
                              return _vm.handleDel(scope.row)
                            },
                          },
                        },
                        [_vm._v("删除")]
                      ),
                      scope.row.audit_status == 0
                        ? _c(
                            "el-button",
                            {
                              attrs: { type: "text" },
                              on: {
                                click: function ($event) {
                                  return _vm.handleEdit(scope.row)
                                },
                              },
                            },
                            [_vm._v("通过")]
                          )
                        : _vm._e(),
                    ]
                  },
                },
              ]),
            }),
          ],
          1
        ),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "fy" },
      [
        _c("el-pagination", {
          attrs: {
            type: "primary",
            background: "",
            "current-page": _vm.currentPage,
            "page-sizes": [10, 20, 50, 100],
            "page-size": _vm.from.pageSize,
            layout: "total, sizes, prev, pager, next, jumper",
            total: _vm.total,
          },
          on: {
            "size-change": _vm.handleSizeChange,
            "current-change": _vm.handleCurrentChange,
          },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }